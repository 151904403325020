import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import "./App.css";

import { ToastContainer } from "react-toastify";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as locales from "@mui/material/locale";

import theme from "../theme";
import RequireAuth from "../helpers/RequireAuth";
import RequireRight from "../helpers/RequireRight";

import MainLayout from "./MainLayout";
import NotFound from "./NotFound";
import Login from "./Login";
import Spinner from "./Spinner";

import modules from "../modules";

const App = () => {
    const locale = useSelector((state) => state.auth.locale);

    // mui uses enUS and not en-US
    const themeWithLocale = useMemo(() => createTheme(theme, locales[locale.replace("-", "")]), [locale]);

    return (
        <ThemeProvider theme={themeWithLocale}>
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <RequireAuth>
                                <MainLayout />
                            </RequireAuth>
                        }
                    >
                        {modules.map((module) => module.children.map((module) => <Route path={module.path} exact element={<RequireRight right={module.right}>{module.element ? module.element : null}</RequireRight>} />))}

                        <Route path="*" element={<NotFound />} />
                    </Route>
                    <Route path="/login" element={<Login />} />
                </Routes>
            </BrowserRouter>
            <Spinner />
            <ToastContainer closeButton={false} autoClose={3000} closeOnClick pauseOnFocusLoss={false} />
        </ThemeProvider>
    );
};

export default App;
