import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import { Collapse, Typography } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import modules from "../modules";

const itemStyle = {
    px: 3,
    pt: 1,
    color: "rgba(255, 255, 255, 0.7)",
    "&:hover, &:focus": {
        bgcolor: "rgba(255, 255, 255, 0.08)",
    },
    fontSize: "16px",
};

const itemCategoryStyle = {
    boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
    py: 1.5,
    px: 3,
};

export default function Sidebar(props) {
    const { ...other } = props;
    const { t } = useTranslation();

    const userRights = useSelector((state) => state.auth.rights);

    const location = useLocation();
    const [open, setOpen] = useState(0);

    useEffect(() => {
        modules.forEach((module) => {
            setOpen(module.id);
        });
    }, []);

    const renderModules = (module) => {
        // renders the category if the user has at least the rights for at least one module
        const hasRight = (!module.children && module.sidebar && userRights.includes(module.right)) || (module.children && module.children.find((child) => userRights.includes(child.right)));
        if (hasRight) {
            const modulesList = (
                <>
                    <ListItem disablePadding key={module.id}>
                        <ListItemButton
                            selected={module.path && location.pathname.substring(0, module.path.length) === module.path}
                            sx={itemStyle}
                            component={module.path ? Link : null}
                            // eslint-disable-next-line no-nested-ternary
                            to={module.path ? (module.element ? module.path : process.env.REACT_APP_V1_BASE_URL.concat(module.path)) : ""}
                            onClick={() => {
                                if (!module.path) {
                                    setOpen({ [module.id]: !open[module.id] });
                                }
                            }}
                        >
                            <ListItemIcon sx={{ pr: 1 }}>{module.icon}</ListItemIcon>
                            <ListItemText>
                                <Typography variant="sidebar">{t(`${module.id}`)}</Typography>
                            </ListItemText>
                            {open[module.id] ? module.children && <ExpandLess /> : module.children && <ExpandMore />}
                        </ListItemButton>
                    </ListItem>
                    {module.children && module.children.length > 0 && (
                        <Collapse in={open[module.id]} timeout="auto" unmountOnExit>
                            <List sx={{ pl: 1 }}>{Array.isArray(module.children) && module.children.map((leaves) => renderModules(leaves))}</List>
                        </Collapse>
                    )}
                </>
            );
            return (
                <Box key={module.id}>
                    {modulesList}
                    {!module.path && <Divider />}
                </Box>
            );
        }
        return null;
    };

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Drawer variant="permanent" {...other}>
            <List disablePadding>
                <ListItem
                    component={Link}
                    to="/"
                    sx={{
                        ...itemStyle,
                        ...itemCategoryStyle,
                        fontSize: 25,
                        backgroundColor: "#fff!important",
                    }}
                >
                    <Typography variant="title" color="primary">
                        {t("app_name")}
                    </Typography>
                </ListItem>
                <ListItem component={Link} to="/" sx={{ ...itemStyle, ...itemCategoryStyle, typography: "title" }}>
                    <ListItemIcon sx={{ pr: 1 }}>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <Typography variant="sidebar">{t("home")}</Typography>
                    </ListItemText>
                </ListItem>
                {modules.map((module) => renderModules(module))}
            </List>
        </Drawer>
    );
}
