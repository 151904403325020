import { jadApi } from "./jadApi";

export const networksService = jadApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllNetworks: builder.query({
            // get all networks
            query: () => ({
                url: "/networks/all",
                method: "GET",
            }),
            transformResponse: (response) => response.data,
        }),
        getNetworks: builder.query({
            // get user networks
            query: () => ({
                url: "/networks",
                method: "GET",
            }),
            transformResponse: (response) => response.data,
        }),
    }),
    overrideExisting: false,
});

export const { useGetAllNetworksQuery, useGetNetworksQuery } = networksService;
