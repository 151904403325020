/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Select, Box, MenuItem, FormControl, FormControlLabel, FormGroup, TextField, Switch, InputLabel, RadioGroup, Checkbox, useMediaQuery, useTheme } from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { DevTool } from "@hookform/devtools";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import ModuleNav from "../../ModuleNav";
import navconfig from "./navconfig";
import TransferList from "../../TransferList";
import { useGetUserQuery, useGetUploadNetworksQuery, useAddNewUserMutation, useUpdateUserMutation, useStoreUploadNetworksMutation } from "../../../services/users.service";
import { useGetRightsQuery } from "../../../services/rights.service";
import { useGetLanguagesQuery } from "../../../services/languages.service";
import { useGetAllNetworksQuery } from "../../../services/networks.service";

const UsersEdit = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    let { id } = useParams();
    id = parseInt(id, 10);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const newUser = {
        first_name: "",
        last_name: "",
        email: "",
        language_id: 2,
        status: false,
        networks: [],
    };

    const {
        data: user = newUser,
        isLoading: isFetchingUser,
        refetch,
    } = useGetUserQuery(id, {
        skip: id === 0,
    });

    const validationSchema = Yup.object().shape({
        firstname: Yup.string()
            .required(t("validation_required"))
            .min(2, t("validation_min_2"))
            .max(30, t("validation_max_30"))
            .matches(/^[aA-zZÀ-ù-\s]+$/, t("validation_alpha_spaces")),
        lastname: Yup.string()
            .required(t("validation_required"))
            .min(2, t("validation_min_2"))
            .max(30, t("validation_max_30"))
            .matches(/^[aA-zZÀ-ù-\s]+$/, t("validation_alpha_spaces")),
        email: Yup.string().required(t("validation_required")).email(t("validation_email")),
    });

    const methods = useForm();
    const { control, handleSubmit, formState, register, setValue, reset, getValues } = useForm({
        defaultValues: user,
        mode: "onChange",
        resolver: yupResolver(validationSchema),
    });
    const { data: languages = [], isLoading: isFetchingLanguages } = useGetLanguagesQuery();
    const { data: permissions = [] } = useGetRightsQuery();
    const { data: networks = [] } = useGetAllNetworksQuery();
    const { data: userNetworksUpload = [], refetch: refetchUserNetworkUpload } = useGetUploadNetworksQuery(id, { skip: id === 0 });
    const [networksUploadSelected, setNetworksUploadSelected] = useState([]);
    const [networksUploadList, setNetworksUploadList] = useState([]);
    const [addNewUser] = useAddNewUserMutation();
    const [updateUser] = useUpdateUserMutation();
    const [storeUploadNetworks] = useStoreUploadNetworksMutation();

    const getFormatedSelectedNetworksRights = () => {
        const formatedList = [];
        Object.values(getValues("networks")).forEach((value) =>
            formatedList.push({
                id: value.network_id,
                name: value.network_name,
                ro: value.ro,
                rwp: value.rwp,
                rw: value.rw,
                access_type: value.access_type,
            })
        );
        return formatedList;
    };

    const saveUploadNetworks = (userId) => {
        if (userId > 0) {
            const formattedNetworksToSave = [];
            Object.values(networksUploadSelected).forEach((value) =>
                formattedNetworksToSave.push({
                    network_id: value.id,
                    network_name: value.name,
                })
            );
            storeUploadNetworks({ user_id: userId, networks: formattedNetworksToSave })
                .unwrap()
                .then((response) => {
                    refetchUserNetworkUpload();
                })
                .catch((error) => console.log("error", error));
        }
    };

    const userErrorCallback = (error) => {
        toast.error(error.error.message, { pauseOnFocusLoss: false });
        Object.keys(error.error.errors).forEach((field) => Object.values(error.error.errors[field]).forEach((oneError) => toast.error(`${field} : ${oneError}`, { pauseOnFocusLoss: false })));
    };

    const save = (datas) => {
        // difference de nommage en fonction des appels api
        datas.networks = getFormatedSelectedNetworksRights();

        if (id === 0) {
            addNewUser(datas)
                .unwrap()
                .then((payload) => {
                    toast.success(t("save_data_success"));
                    saveUploadNetworks(payload.id);
                    navigate(`/administration/users/${payload.id}`, {
                        replace: true,
                    });
                })
                .catch((error) => userErrorCallback(error));
        } else {
            updateUser(datas)
                .unwrap()
                .then((user) => {
                    toast.success(t("save_data_success"));
                    saveUploadNetworks(id);
                    // toast.success(t("users.form.userRecorded"));
                    reset(user);
                    refetch();
                })
                .catch((error) => userErrorCallback(error));
        }
    };

    const handlePermissions = (datas) => {
        setValue("rights", datas);
    };

    const handleNetworksUploadRights = (datas) => {
        setNetworksUploadSelected(datas);
    };

    const handleNetworks = (e) => {
        const id = e.target.name.split("_", 2);
        let currentValue = getValues().networks.find((value) => value !== undefined && value.network_id === Number(id[0]));
        if (currentValue === undefined) {
            const currentNetwork = networks.find((value) => value !== undefined && value.network_id === Number(id[0]));
            if (currentNetwork !== undefined) {
                getValues().networks.push({
                    ...currentNetwork,
                    ro: false,
                    rw: false,
                    rwp: false,
                    access_type: "",
                });
                currentValue = getValues().networks.find((value) => value !== undefined && value.network_id === Number(id[0]));
            }
        }
        if (currentValue !== undefined) {
            switch (id[1]) {
                case "RO":
                    currentValue.ro = !currentValue.ro;
                    currentValue.rw = false;
                    currentValue.rwp = false;
                    currentValue.access_type = "RO";
                    break;
                case "RW":
                    currentValue.rw = !currentValue.rw;
                    currentValue.ro = false;
                    currentValue.rwp = false;
                    currentValue.access_type = "RW";
                    break;
                case "RWP":
                    currentValue.rwp = !currentValue.rwp;
                    currentValue.ro = false;
                    currentValue.rw = false;
                    currentValue.access_type = "RWP";
                    break;
                default:
                    break;
            }
            const currentIndex = getValues().networks.findIndex((value) => value !== undefined && value.network_id === Number(id[0]));
            const update = getValues().networks;
            update[currentIndex] = currentValue;
            setValue("networks", update);
        }
    };

    const firstNameError = formState.errors?.firstname?.message;
    const lastNameError = formState.errors?.lastname?.message;
    const emailError = formState.errors?.email?.message;
    const getUserLabel = (label) => {
        let userLabel = label;
        userLabel += ` ${user?.firstname}`;
        userLabel += ` ${user?.lastname} `;
        return userLabel;
    };

    const loadSelectedNetworksUpload = () => {
        const selectedNetworks = [];
        Object.values(userNetworksUpload).forEach((value) =>
            selectedNetworks.push({
                id: value.network_id,
                name: value.network_name,
            })
        );
        setNetworksUploadSelected(selectedNetworks);
    };

    useEffect(() => {
        reset(user);
    }, [isFetchingUser, isFetchingLanguages]);

    useEffect(() => {
        setNetworksUploadList(networks);
    }, [networks]);

    useEffect(() => {
        loadSelectedNetworksUpload();
    }, [userNetworksUpload.length]);

    return (
        <>
            <ModuleNav title={navconfig.title} tabs={navconfig.tabs} icon={navconfig.icon} />
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(save)}>
                    <Box sx={{ "& > :not(style)": { m: 3 } }}>
                        <FormGroup row={!isMobile} sx={{ "& > :not(style)": { mr: 3, mt: isMobile ? 3 : 0, width: isMobile ? "max-content" : "auto" } }}>
                            <TextField inputProps={{ maxLength: 30 }} InputLabelProps={{ shrink: true }} label={isMobile ? "toto" : t("firstname")} {...register("firstname")} error={Boolean(firstNameError)} helperText={firstNameError} />
                            <TextField inputProps={{ maxLength: 30 }} InputLabelProps={{ shrink: true }} label={t("lastname")} {...register("lastname")} error={Boolean(lastNameError)} helperText={lastNameError} />
                        </FormGroup>
                        <FormGroup sx={{ "& > :not(style)": { mr: 3, width: isMobile ? "max-content" : "60ch" } }}>
                            <TextField InputLabelProps={{ shrink: true }} label={t("email")} {...register("email")} error={Boolean(emailError)} helperText={emailError} />
                        </FormGroup>
                        <FormGroup sx={{ "& > :not(style)": { mr: 3, width: isMobile ? "max-content" : "60ch" } }}>
                            <FormControl>
                                <InputLabel id="language-label">{t("language")}</InputLabel>
                                <Controller
                                    name="language_id"
                                    control={control}
                                    defaultValue={2}
                                    render={({ field }) => (
                                        <Select {...field} label={t("language")} labelId="language-label">
                                            {languages.map((lang) => (
                                                <MenuItem key={lang.id} value={lang.id}>
                                                    {lang.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </FormGroup>
                        <FormGroup>
                            <Controller
                                name="status"
                                defaultValue={0}
                                control={control}
                                render={({ field: { onChange, onBlur, value, ref } }) => <FormControlLabel control={<Switch checked={value || false} onChange={onChange} onBlur={onBlur} ref={ref} />} label={t("active")} />}
                            />
                            <Controller
                                name="is_admin"
                                defaultValue={false}
                                control={control}
                                render={({ field: { onChange, onBlur, value, ref } }) => <FormControlLabel control={<Switch checked={value || false} onChange={onChange} onBlur={onBlur} ref={ref} />} label={t("admin")} />}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Card>
                                <CardHeader titleTypographyProps={{ variant: "h5" }} title={getUserLabel(t("user_rights_link_edit"))} />
                                <Controller
                                    name="rights"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <TransferList available={permissions} selected={value || []} onChange={handlePermissions} availableListLabel={t("user_rights_available")} selectedListLabel={t("user_rights_selected")} />
                                    )}
                                />
                            </Card>
                        </FormGroup>
                        <FormGroup>
                            <Box>
                                <Card>
                                    <CardHeader titleTypographyProps={{ variant: "h5" }} title={getUserLabel(t("user_rights_networks_link_edit"))} />
                                    <CardContent>
                                        <Controller
                                            name="networks"
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <TableContainer component={Paper}>
                                                    <Table size="small" aria-label={getUserLabel(t("user_rights_networks_link_edit"))}>
                                                        <TableBody>
                                                            {networks.map((network) => (
                                                                <TableRow key={network.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                                                    <TableCell component="th" scope="row">
                                                                        {network.name}
                                                                    </TableCell>
                                                                    <RadioGroup row aria-labelledby={network.name} name={network.name} sx={{ ml: 0 }}>
                                                                        <TableCell align="right">
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        name={`${network.id}_RO`}
                                                                                        onChange={handleNetworks}
                                                                                        checked={
                                                                                            !!(
                                                                                                value &&
                                                                                                value.filter((networkValue) => networkValue.network_id === network.network_id).length &&
                                                                                                value.filter((networkValue) => networkValue.network_id === network.network_id)[0].ro
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                }
                                                                                label="RO"
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        name={`${network.id}_RW`}
                                                                                        onChange={handleNetworks}
                                                                                        checked={
                                                                                            !!(
                                                                                                value &&
                                                                                                value.filter((networkValue) => networkValue.network_id === network.network_id).length &&
                                                                                                value.filter((networkValue) => networkValue.network_id === network.network_id)[0].rw
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                }
                                                                                label="RW"
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        name={`${network.id}_RWP`}
                                                                                        onChange={handleNetworks}
                                                                                        checked={
                                                                                            !!(
                                                                                                value &&
                                                                                                value.filter((networkValue) => networkValue.network_id === network.network_id).length &&
                                                                                                value.filter((networkValue) => networkValue.network_id === network.network_id)[0].rwp
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                }
                                                                                label="RWP"
                                                                            />
                                                                        </TableCell>
                                                                    </RadioGroup>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            )}
                                        />
                                    </CardContent>
                                </Card>
                            </Box>
                        </FormGroup>
                        <FormGroup>
                            <Card>
                                <CardHeader titleTypographyProps={{ variant: "h5" }} title={t("user_rights_networks_upload_edit")} />
                                <TransferList
                                    available={networksUploadList}
                                    selected={networksUploadSelected}
                                    onChange={handleNetworksUploadRights}
                                    availableListLabel={t("user_rights_networks_upload_available_networks")}
                                    selectedListLabel={t("user_rights_networks_upload_selected_networks")}
                                />
                            </Card>
                        </FormGroup>

                        <Button type="submit" disabled={formState.isSubmitting} variant="contained">
                            {t("save")}
                        </Button>
                    </Box>
                </form>
            </FormProvider>
            <DevTool control={control} />
        </>
    );
};

export default UsersEdit;
