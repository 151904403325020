import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Button, Container, Grid, Paper, TextField, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useForm, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useLazyGetTrailerJsonQuery } from "../../../services/trailers.service";

import ModuleNav from "../../ModuleNav";
import navconfig from "./navconfig";

const Trailers = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [trigger] = useLazyGetTrailerJsonQuery();
    const [trailerJson, setTrailerJson] = useState();

    const validationSchema = Yup.object().shape({
        lineItemId: Yup.string().required(),
        mediaId: Yup.string().required(),
    });

    const { register, watch, formState, control, handleSubmit, reset, getValues, setValue } = useForm({
        mode: "onChange",
        resolver: yupResolver(validationSchema),
        defaultValues: {},
    });
    const { errors, isValid } = formState;

    const getJson = (values) => {
        setTrailerJson(null);
        if (isValid) {
            trigger(values)
                .unwrap()
                .then((res, error) => {
                    setTrailerJson(JSON.stringify(res).replace(",", ", "));
                });
            return true;
        }
        return false;
    };

    return (
        <div>
            <ModuleNav title={navconfig.title} tabs={navconfig.tabs} icon={navconfig.icon} />
            <Container>
                <form onSubmit={handleSubmit(getJson)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField fullWidth label={t("tools_trailers_line_item_id")} error={Boolean(errors?.lineItemId?.message)} helperText={errors?.lineItemId?.message} {...register("lineItemId")} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth label={t("tools_trailers_media_id")} error={Boolean(errors?.mediaId?.message)} helperText={errors?.mediaId?.message} {...register("mediaId")} />
                        </Grid>

                        <Grid item xs={12}>
                            <Button color="primary" variant="contained" type="submit">
                                {t("tools_trailers_generate_json")}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>

            {trailerJson && (
                <Container>
                    <Grid container rowSpacing={1}>
                        <Grid item xs={12}>
                            <Typography component="h6" variant="h6" color="text.primary" gutterBottom>
                                {t("tools_trailers_json_to_copy")}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    toast.success(t("ok"));
                                    navigator.clipboard.writeText(trailerJson);
                                }}
                            >
                                {t("copy")}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper sx={{ padding: 1, fontSize: 11 }}>
                                <div style={{ overflowWrap: "anywhere" }}>{trailerJson}</div>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            )}
        </div>
    );
};

export default Trailers;
