import * as React from "react";
import { Outlet } from "react-router-dom";

import useMediaQuery from "@mui/material/useMediaQuery";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import theme from "../theme";

import Sidebar from "./Sidebar";
import Header from "./Header";

const drawerWidth = 268;

const MainLayout = () => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Box sx={{ display: "flex", minHeight: "100vh" }}>
            <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
                {isSmUp ? null : <Sidebar PaperProps={{ style: { width: drawerWidth, scrollbarWidth: "none" } }} variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} />}
                <Sidebar PaperProps={{ style: { width: drawerWidth, scrollbarWidth: "none", borderRight: "none" } }} sx={{ display: { zIndex: 0, sm: "block", xs: "none" } }} />
            </Box>
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <Header onDrawerToggle={handleDrawerToggle} />
                <Box component="main" sx={{ flex: 1, py: 0, px: 0 }}>
                    <Outlet />
                </Box>
                <Box component="footer" sx={{ p: 2, bgcolor: "#eaeff1" }}>
                    <Typography variant="body2" color="text.secondary" align="center">
                        Copyright © Webedia - {new Date().getFullYear()}.
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default MainLayout;
