import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, IconButton, Container, Grid, formLabelClasses, Paper, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridCheckIcon, GridCloseIcon } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import { useTheme } from "@emotion/react";

import { useGetUsersQuery } from "../../../services/users.service";

import ModuleNav from "../../ModuleNav";
import navconfig from "./navconfig";

const UsersList = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const { data, error, isLoading, refetch } = useGetUsersQuery();
    const handleEdit = function (params) {
        navigate("/administration/users/edit/".concat(params.id));
    };
    const handleLock = function (params) {
        const userId = params.row.id;
        /*
        if (!!params.row.status) {
            setModalParams({ title: t("disable_user"), message: t("ask_disable_user"), yes: toggleUserStatus });
        } else {
            setModalParams({ title: t("activate_user"), message: t("ask_activate_user"), yes: toggleUserStatus });
        }
            */
    };

    const columns = [
        { field: "id", headerName: "id", width: 70 },
        { field: "firstname", headerName: t("firstname"), width: 150 },
        { field: "lastname", headerName: t("name"), width: 150 },
        { field: "email", headerName: t("email"), width: 300 },
        {
            field: "status",
            headerName: t("status"),
            width: 100,
            type: "boolean",
            editable: true,
            renderCell: (params) =>
                params.value ? (
                    <GridCheckIcon
                        style={{
                            color: theme.palette.success.main,
                        }}
                    />
                ) : (
                    <GridCloseIcon
                        style={{
                            color: theme.palette.error.main,
                        }}
                    />
                ),
        },
        {
            field: "is_admin",
            headerName: t("admin"),
            width: 100,
            type: "boolean",
            renderCell: (params) =>
                params.value ? (
                    <GridCheckIcon
                        style={{
                            color: theme.palette.success.main,
                        }}
                    />
                ) : (
                    <GridCloseIcon
                        style={{
                            color: theme.palette.error.main,
                        }}
                    />
                ),
        },
        {
            field: "actions",
            headerName: t("actions"),
            width: 100,
            type: "actions",
            getActions: (params) => [
                <GridActionsCellItem icon={<EditIcon color="primary" />} label={t("edit")} onClick={() => handleEdit(params)} />,
                <GridActionsCellItem icon={params.row.status ? <LockOpenIcon color="success" /> : <LockIcon color="error" />} label={t("lock")} onClick={() => handleLock(params)} />,
            ],
        },
    ];

    const [sortModel, setSortModel] = React.useState([
        {
            field: "Name",
            sort: "asc",
        },
    ]);

    return (
        <>
            <ModuleNav title={navconfig.title} tabs={navconfig.tabs} icon={navconfig.icon} />
            <Box sx={{ ml: 3, mt: 2, maxWidth: "1200px" }}>
                <Typography gutterBottom variant="h4" sx={{ display: "inline-block", textTransform: "capitalize" }}>
                    {t("users_list")}
                </Typography>{" "}
                <Button
                    variant="contained"
                    sx={{ float: "right" }}
                    onClick={() => {
                        navigate("/administration/users/edit/0");
                    }}
                >
                    {t("add_user")}
                </Button>
                {isLoading ? (
                    <p>Loading</p>
                ) : (
                    <Box>
                        <DataGrid autoHeight columns={columns} rows={Object.values(data)} />
                    </Box>
                )}
            </Box>
        </>
    );
};

export default UsersList;
