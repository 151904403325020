import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import TextField from "@mui/material/TextField";
import CardHeader from "@mui/material/CardHeader";
import { Paper } from "@mui/material";

const TransferList = (props) => {
    const { available, selected, onChange, availableListLabel, selectedListLabel } = props;
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const [rightFilter, setRightFilter] = useState("");
    const [leftFilter, setLeftFilter] = useState("");
    const { t } = useTranslation();

    const not = (a, b) => a.filter((value) => b.map((value) => value.name).indexOf(value.name) === -1);

    const intersection = (a, b) => a.filter((value) => b.map((value) => value.name).indexOf(value.name) !== -1);
    const filterList = (list, filterText) => (filterText.length > 0 ? list.filter((entry) => entry.name.toLowerCase().includes(filterText.toLowerCase())) : list);

    useEffect(() => {
        setLeft(
            not(available, selected).sort((a, b) => {
                if (a.name > b.name) return 1;
                return -1;
            })
        );
        setRight(
            selected.sort((a, b) => {
                if (a.name > b.name) return 1;
                return -1;
            })
        );
    }, [selected, available]);

    useEffect(() => {
        onChange(right);
    }, [right.length]);

    const toggle = (e, partLabel) => {
        console.log(partLabel);
        if (partLabel === "left" && not(right, [{ name: e.target.innerText }])) {
            const intersect = intersection(left, [{ name: e.target.innerText }]);
            setRight(
                right.concat(intersection(left, intersect)).sort((a, b) => {
                    if (a.name > b.name) return 1;
                    return -1;
                })
            );
            setLeft(not(left, intersect));
        }
        if (partLabel === "right" && not(left, [{ name: e.target.innerText }])) {
            const intersect = intersection(right, [{ name: e.target.innerText }]);
            setLeft(
                left.concat(intersection(right, intersect)).sort((a, b) => {
                    if (a.name > b.name) return 1;
                    return -1;
                })
            );
            setRight(not(right, intersect));
        }
    };
    // met a jour le filtre
    const handleFilterChange = (event, partLabel) => (partLabel === "left" ? setLeftFilter(event.target.value) : setRightFilter(event.target.value));

    const customList = (items, titleList, partLabel) => (
        <Paper sx={{ width: "100%", height: 400 }}>
            <CardHeader title={titleList} titleTypographyProps={{ variant: "h6" }} sx={{ pb: 1 }} />
            <List
                dense
                component="div"
                role="list"
                sx={{
                    height: 300,
                    bgcolor: "background.paper",
                    overflow: "auto",
                }}
            >
                <ListSubheader component="div">
                    <TextField
                        variant="standard"
                        label={t("filter")}
                        type="text"
                        name="filter"
                        defaultValue=""
                        onChange={(event) => handleFilterChange(event, partLabel)}
                        onKeyDown={(event) => event.stopPropagation()}
                        onClick={(event) => event.stopPropagation()}
                    />
                </ListSubheader>
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value.id}-label`;

                    return (
                        <ListItemButton key={value.id} role="listitem" onClick={(e) => toggle(e, partLabel)} sx={{ "&:hover": { bgcolor: "primary.light" } }}>
                            <ListItemText id={labelId} primary={value.name} />
                        </ListItemButton>
                    );
                })}
            </List>
        </Paper>
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                {customList(filterList(left, leftFilter), availableListLabel, "left")}
            </Grid>
            <Grid item xs={6}>
                {customList(filterList(right, rightFilter), selectedListLabel, "right")}
            </Grid>
        </Grid>
    );
};

export default TransferList;
