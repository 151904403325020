import React from "react";
import MovieIcon from "@mui/icons-material/Movie";

const navconfig = {
    title: "trailers",
    icon: <MovieIcon />,
    tabs: [
        // { label: "users.list", url: "/users" },
        // { label: "super", url: "/users?a=1" },
        // { label: "bof bof", url: "/users/bofbof" },
        // { label: "ouaf ouaf", url: "/users/ouafouaf" },
    ],
};

export default navconfig;
